import React, { useEffect, useState } from 'react';   
import SectionTitle from "../SectionTitles/SectionTitle";
import {Link} from 'react-router-dom'; 
import axios from 'axios';
import SlickSlider from '../UI/Carousel/slick';

const RelatedServices = ({serviceList}) => {
    
    // servicelist was perviously was from the parent service
    // as per requirement it is now the service list accordingly

    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
            const response = await axios.get('https://portal.ecotecglobal.net/api/services');
                setData(response.data.data);
                // console.log('akash', response.data.data[1].service_category[0].services[0])
            } catch (err) {
                console.log(err);
            } finally {
                // nothing
            }
        };
        
        fetchData();
    }, []);

    const settings = {
        dots: false,
        arrows:false,
        infinite: true, 
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
          responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                }
              } 
 
            ]
      };

    return (
    <section className="single_service_s2">
        <div className="container">
            {/* Heading */} 
            <SectionTitle 
                title="What we do for you" 
            />
            {/* End: Heading */ }
            <div className="row">
                {/* {
                    serviceList.reverse().slice(0, 3).map((service, id) => ( 
                        <div className="col-md-4 col-sm-12" key={id}>
                            <div className="service_list">
                                <div className="service_img"> 
                                    <img src={('https://portal.ecotecglobal.net/public/' + service.thumb)} alt="service" />
                                </div>   
                                <div className="service_para">
                                    <i className="icon_easel"></i> <h5> {service.title} </h5>
                                    <p>{service.shortDesc}</p>  
                                    <Link to={`${process.env.PUBLIC_URL + `/service/${service.title.split(' ').join('-').toLowerCase()}?id=${service.id} `}`} className="srvic_read">Read More <span className="fa fa-angle-double-right"></span></Link>
                                </div>
                            </div> 
                        </div>
                    ))
                } */}
                {
                    (data != null)?<SlickSlider settings={settings}>
                    {
                        data.map((service, id) => ( 
                            <div className="col-md-4 col-sm-12 p-1" key={id}>
                                <div className="service_list">
                                    <div className="service_img"> 
                                        <Link to={`${process.env.PUBLIC_URL +`service/details?id=${service.service_category[0].services[0].id}`}`}>
                                            <img className={serviceList.length <= 1?"service_img_thumb2":"service_img_thumb1"} src={('https://portal.ecotecglobal.net/public/' + service.service_category[0].services[0].singleThumb)} alt="service" />
                                        </Link>
                                    </div>   
                                    <div className="service_para">
                                        <i className="icon_easel"></i> 
                                        <Link to={`${process.env.PUBLIC_URL +`service/details?id=${service.service_category[0].services[0].id}`}`}>
                                            <h5> {service.title} </h5>
                                        </Link>
                                        <p>{service.service_category[0].services[0].shortDesc}</p>  
                                        
                                        <Link to={`${process.env.PUBLIC_URL +`service/details?id=${service.service_category[0].services[0].id}`}`} className="srvic_read">Read More <span className="fa fa-angle-double-right"></span></Link>
                                    </div>
                                </div> 
                            </div>
                        ))
                    }
                    </SlickSlider>:null
                } 
            </div>
        </div>
    </section>
    );
};

export default RelatedServices;