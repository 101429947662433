import React, { Fragment, useEffect, useState } from "react";  
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";  
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import {Link} from "react-router-dom"; 
import axios from 'axios';
import ServicesData from '../../data/Services/EnvironmentalServices.json' 


const ServiceContentWrap = ({service}) => {
    // servicelist was perviously was from the parent service
    // as per requirement it is now the service list accordingly
    
    const [data, setData] = useState(null);
    const [serviceList, setServiceList] = useState([]);

    useEffect(() => {
        const fetchData = async (id) => {
            try {
				const response = await axios.get(`https://portal.ecotecglobal.net/api/category-wise-service/${id}`);
				setServiceList(response.data.data);
			} catch (err) {
				console.log(err);
			} finally {
				// nothing
			}
        };
        fetchData(service.service_category_id);
    }, [service]);

    return (
        <Fragment>

            <div className="col-md-9 col-sm-12 single_service_page_content"> 
            <img src={('https://portal.ecotecglobal.net/public/' + service.singleThumb)} alt="service" />
                <div className="single_service_title">
                    <h3>{service.title}</h3> 
                    {<div dangerouslySetInnerHTML={{__html: service.description}}/>}
                </div>
            </div>

            <Sidebar classes={'col-md-3 col-sm-12 single_service_side_bar'}>
                <SidebarItem classes="single_service_category">
                    <List>
                        {
                            (serviceList != null) ? serviceList.map((serviceItem,id)=>(
                                <LI key={id}><i className="fa fa-angle-right"></i>
                                    <Link to={`${process.env.PUBLIC_URL +`service/details?id=${serviceItem.id}`}`}>
                                        {serviceItem.title}
                                    </Link>
                                </LI>
                            )) : null
                        }
                    </List> 
                </SidebarItem>
            </Sidebar>

        </Fragment>
    );
};

export default ServiceContentWrap;